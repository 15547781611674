<template>
    <div style="padding-bottom: 12px;" v-if="haveData">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhNoDropSelector v-if="label.show"
                :datablock="{
                    id: 'contracted_programs_' + label.id,
                    title: $t(label['name']),
                    label: ''
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhNoDropSelector>
        </div>

        <component v-bind:is="components.popup_assign" :refresher="refreshAssign"/>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";

    import contracted_programs from "@/view/itinerary/client/contracted_programs/contracted_programs/contracted_programs.vue";
    import popup_assign from '@/view/shared/popup/popup_assign.vue';

    export default {
        name: 'list_contracted_programs',
        components: {
            GhNoDropSelector
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'contracted_programs', component: contracted_programs, result: '', show: true , second_text: ''},
                ],
                components: {
                    popup_assign: popup_assign
                },
                refreshAssign: 0,
                haveData: false
            }
        },
        async beforeMount(){
            this.haveData = true;
        }
    }
</script>

<style>
    [id^="contracted_programs_"] > div > div > .collapsible_section-label{
        padding-top: 3px;
    }
</style>