import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";

Vue.use(vuex);

export default{
    state: {
        records_data: ''
    },
    mutations: {
        loadEvaluation(state, payload) {
            state.records_data = payload;
        }
    },
    actions: {
        async getEvaluation(state){
            await axios.get(
                API_URL + "evaluations"
            ).then(
                response => {
                    state.commit('loadEvaluation', response.data.data);
                }
            )
        },
        async getAssociateEvaluation(state,item){
            return await axios.get(
                API_URL + "evaluations/items/" + item.id
            ).then(
                response => {
                    return response;
                }
            )
        },
        async setAssociateEvaluation(state, item){
            let formData = new FormData();
            let requestLogin = '';
            if(item.selected !== undefined){
                requestLogin = API_URL + "evaluations/agrupators/"  + item.id ;
                formData.append('evaluation[evaluation_basis]', item.selected);
            }else {
                requestLogin = API_URL + "evaluations/items/"  + item.id ;
                item.formData.forEach((item) => {
                    formData.append(item[0], item[1]);
                });
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {}, // eslint-disable-line no-unused-vars
                error => {
                    console.log(error);
                }
            )
        },

    }
};
