<template>
    <div>
        <GhNoDropSelector
            :datablock="{
                id: 'sector_benchmark',
                title: $t('benchmark') + ' - ' + (ConfigBenchmark.data !== undefined && ConfigBenchmark.data.country !== undefined ? ConfigBenchmark.data.country : '') + ' - ' + (ConfigBenchmark.data !== undefined && ConfigBenchmark.data.evaluation !== undefined ? ConfigBenchmark.data.evaluation : ''),
            }"
        >
            <template v-slot:content>
                <form id="form_benchmark">
                    <input type="hidden" name="benchmarck[country_id]" :value="ConfigBenchmark.data !== undefined && ConfigBenchmark.data.country_id !== undefined ? ConfigBenchmark.data.country_id : ''">
                    <GhTableCommon
                        style="margin-top: 20px;"
                        :extratable="{
                            id: 'table_benchmark_sectors',
                        }"
                        :header="header"
                        :data="ConfigBenchmark.data !== undefined && ConfigBenchmark.data.data !== undefined && ConfigBenchmark.data.data.sectors !== undefined && ConfigBenchmark.data.data.sectors.length !== 0 ? ConfigBenchmark.data.data.sectors : []"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <GhInputTextField
                                :datalabel="{
                                    text: '',
                                    style:'',
                                    class: ''
                                }"
                                :datainput="{
                                    required: true,
                                    id: '',
                                    name: 'benchmarck[sectors][' + itemProp.id +'][' + header.find(item => item.field === labelProp)['id'] +']',
                                    style: '',
                                    value: itemProp[labelProp],
                                    type: 'float',
                                    label_required: $t('column') + ': ' + header.find(item => item.field === labelProp)['text'] + ' ' + $t('row') + ': ' + itemProp['name'],
                                }"
                            />
                        </template>
                    </GhTableCommon>

                    <GhTableCommon
                        style="margin-top: 20px;"
                        :extratable="{
                            id: 'table_benchmark_num_employees',
                        }"
                        :header="header"
                        :data="ConfigBenchmark.data !== undefined && ConfigBenchmark.data.data !== undefined && ConfigBenchmark.data.data.num_employeees !== undefined && ConfigBenchmark.data.data.num_employeees.length !== 0 ? ConfigBenchmark.data.data.num_employeees : []"
                    >
                        <template v-slot:field_redirect="{itemProp, labelProp}">
                            <GhInputTextField
                                :datalabel="{
                                    text: '',
                                    style:'',
                                    class: ''
                                }"
                                :datainput="{
                                    required: true,
                                    id: '',
                                    name: 'benchmarck[num_employees][' + itemProp.id +'][' + header.find(item => item.field === labelProp)['id'] +']',
                                    style: '',
                                    value: itemProp[labelProp],
                                    type: 'text',
                                    label_required: $t('column') + ': ' + header.find(item => item.field === labelProp)['text'] + ' ' + $t('row') + ': ' + itemProp['name'],
                                }"
                            />
                        </template>
                    </GhTableCommon>
                </form>
            </template>
        </GhNoDropSelector>

        <div class="centered" style="margin-top: 15px;">
            <GhButton
                :datainput="{
                    id: 'btn_accept',
                    text: $t('accept'),
                }"
                @click="submit"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="$router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    export default {
        name: 'view_benchmark',
        components: {
            GhInputTextField,
            GhTableCommon,
            GhButton,
            GhNoDropSelector
        },
        computed: {
            ...mapState(['ConfigBenchmark'])
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: '', field: 'name', sorting: false, typeSort: 'string', style: 'min-width: 150px; max-width: 150px;'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getBenchmarkData', {id: this.$root._route.params.id}, {root: true});

            if(this.ConfigBenchmark.data !== undefined && this.ConfigBenchmark.data.header !== undefined){
                var num_columns = this.ConfigBenchmark.data.header.length;
                this.ConfigBenchmark.data.header.forEach(item => {
                    var head = {text: item.name, id: item.id, field: item.field, sorting: false, urlRedirect: true, typeSort: 'string', style: 'width: ' + (100/num_columns) + '%'}
                    this.header.push(head);
                });
            }

            this.haveData = true;
        },
        methods:{
            async submit(){
                if(this.Global.checkRequired('form_benchmark') === 'OK') {
                    const formData = new FormData(document.getElementById('form_benchmark'));
                    await this.$store.dispatch('setBenchmarkData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>