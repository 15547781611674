var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[(_vm.haveData)?_c('GhNoDropSelector',{attrs:{"datablock":{id: 'no_drop_selector_user',title: _vm.$t('user')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_users"}},[_c('div',{staticClass:"gh-content-global"},[_c('input',{attrs:{"type":"hidden","name":"user[from_company]","value":"1"}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'user_firstname',
                                    name: 'user[firstname]',
                                    style:'width: 10%',
                                    value: _vm.TemplateUser.user_data.firstname,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') +':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.TemplateUser.user_data.firstname))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('surnames') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'user_last_name',
                                    name: 'user[surname]',
                                    style: 'width: 20%',
                                    value: _vm.TemplateUser.user_data.surname,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surnames') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.TemplateUser.user_data.surname))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('email') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'user_email',
                                    name: 'user[email]',
                                    style: 'width: 20%',
                                    value: _vm.TemplateUser.user_data.email,
                                    type: 'text'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email')+':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.TemplateUser.user_data.email))])]],2)])])]},proxy:true}],null,false,1893398697)}):_vm._e(),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_user_save',
                text: _vm.$t('save')
            },"type":"submit"},on:{"click":_vm.submitUser}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_user_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }