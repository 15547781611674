<template>
    <div class="gh-content-global">
        <div class="gh-row-content" v-if="getterAdvice.user !== undefined && getterAdvice.user !== ''">
            <label class="gh_text_field-label T-subtitle-second" style="margin-left: 12px;">{{$t('evaluator') + ': ' + getterAdvice.user}}</label>
        </div>
        <template v-if="getterAdvice !== undefined && getterAdvice.hard_points !== undefined && getterAdvice.hard_points.length !== 0">
            <div class="gh-row-content">
                <label class="content-star" style="margin-top: -3px;"></label>
                <label class="gh_text_field-label T-subtitle-second" style="margin-left: 10px;">{{$t('hard_points')}}</label>
            </div>
            <template v-for="(item,index) in getterAdvice.hard_points">
                <div class="gh-row-content" :key="'hard_points_' + index" :class="edit ? 'radio-check-new-style' : ''" :style="edit ? 'margin-left: 11px;' : ''">
                    <template v-if="edit">
                        <GhChecks
                            :datainput="{
                                id: 'radio_and',
                                value: index,
                                type: 'checkbox',
                                name: 'hard_points[]',
                                selected: $parent.$parent.$parent.selectedItems !== undefined && $parent.$parent.$parent.selectedItems.hard_points !== undefined && $parent.$parent.$parent.selectedItems.hard_points.includes(index),
                                disabled: false
                            }"
                            :datalabel="{class: '', id: '', text: ''}"
                        />
                    </template>
                    <label class="gh_text_field-label T-text" :style="edit ? 'margin-left: 10px;' : 'margin-left: 20px;'">{{item}}</label>
                </div>
            </template>
            <template v-if="getterAdvice !== undefined && getterAdvice.improvement_opportunities !== undefined && getterAdvice.improvement_opportunities.length !== 0">
                <div class="separador"></div>
            </template>
        </template>
        <template v-if="getterAdvice !== undefined && getterAdvice.improvement_opportunities !== undefined && getterAdvice.improvement_opportunities.length !== 0">
            <div class="gh-row-content">
                <label class="content-warning" style="margin-top: -3px;"></label>
                <label class="gh_text_field-label T-subtitle-second" style="margin-left: 10px;">{{$t('improvement_opportunities')}}</label>
            </div>
            <template v-for="(item,index) in getterAdvice.improvement_opportunities">
                <div class="gh-row-content" :key="'improvement_opportunities_' + index" :class="edit ? 'radio-check-new-style' : ''" :style="edit ? 'margin-left: 11px;' : ''">
                    <template v-if="edit">
                        <GhChecks
                            :datainput="{
                                id: 'radio_and',
                                value: index,
                                type: 'checkbox',
                                name: 'improvement_opportunities[]',
                                selected: $parent.$parent.$parent.selectedItems !== undefined && $parent.$parent.$parent.selectedItems.improvement_opportunities !== undefined && $parent.$parent.$parent.selectedItems.improvement_opportunities.includes(index),
                                disabled: false
                            }"
                            :datalabel="{class: '', id: '', text: ''}"
                        />
                    </template>
                    <label class="gh_text_field-label T-text" :style="edit ? 'margin-left: 10px;' : 'margin-left: 20px;'">{{item}}</label>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'template_advice',
        props: ['edit'],
        components: {
            GhChecks
        },
        computed: {
            ...mapGetters(['getterAdvice'])
        },
    }
</script>