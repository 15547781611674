/**
 * Author: Sunil Shrestha
 * Date: July 26, 2023
 * Version: 2
 */
import { API_URL } from "@/common/config";
import axios from "axios";
import store from "@/store/index";
import router from "@/routes/router";

// Default values for token name
const defaultTokenName = 'token';
// const defaultUserTab  = '/todo';
export const AuthService = {

        checkLocalStorageAndCookie(token_name = defaultTokenName, cookie_name = '', callback_url = '') {
            const _token = this.getToken(token_name)
            const _cookie = this.getCookie(cookie_name);

            if ((!_token || !_cookie) && callback_url) {
                window.location.href = callback_url;
            }
        },
        getCookie(name) {
            const value = "; " + document.cookie;
            const parts = value.split("; " + name + "=");
            if (parts.length === 2) {
                return parts.pop().split(";").shift();
            }
            return null;
        },
        getToken(name) {
            const token = localStorage.getItem(name);
            return token ? token : null;
        },
        getAuthType: async function () {

            localStorage.clear();
            let endpoint = API_URL + 'auth/parameters' ;
            const response = await axios.get(endpoint);
            const authType = response.data; // Modify this based on your API response
            return authType;

        },
        getLogoutUrl: async function () {
            let endpoint = API_URL + 'auth/sso-config' ;
            const response = await axios.get(endpoint);
            const authType = response.data; // Modify this based on your API response
            return authType;

        },
        doAuthSSO:  async function() {
            const apiEndpoint = API_URL + "auth/login"; // Replace this with the actual API endpoint URL
            let data = {}; // Add your request data here
            const response = await axios.post(apiEndpoint, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            localStorage.clear();
            store.commit("LOAD_ERROR", '');
            await store.dispatch('setUserAuthData', response.data.data) ;
            await store.dispatch('setTokenExpiration', response.data.data) ;
            await store.dispatch('getTabsWithPerm', response.data.data) ;
            await store.dispatch('resetAppTabs');
            await store.dispatch('getEnvironmentStyles');
            await this.redirectUserAfterAuth(response.data.data);
        },
        redirectUserAfterAuth: async function(data){
            // const savedTabItem = localStorage.getItem('TabItem');
            // let targetRoute = '';
            console.log(data);
            // if(data.questionary_done === 0)
            // {
            //     targetRoute = 'initial_itinerary';
            // }
            // else
            // {
            //     targetRoute = savedTabItem
            //         ? router.options.routes.find((route) => route.name === savedTabItem)?.path
            //         : defaultUserTab;
            //     console.log(targetRoute);
            // }

            if(data.questionary_done === 1){
                router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route) => route.name == localStorage.getItem('TabItem'))].path : '/config'});
            }else{
                await axios.get(
                    API_URL + "initial/id"
                ).then(
                    response_2 => {
                        store.commit('loadNameCompany', response_2.data.data.company);
                        router.push({name: 'initial_itinerary',params:{id: response_2.data.data.id, type: 'initial'}});
                    }
                )
            }

            // router.push({ path: targetRoute });
            location.reload();
        },
        isAuthenticated: function () {
            return !!localStorage.getItem(defaultTokenName);
        },
        getAccessToken: function () {
            return localStorage.getItem(defaultTokenName);
        },
        setAuthType: function (auth_type) {
            store.commit("setAppAuthType", auth_type);
        },
        verifyToken: async function () {
            const token = this.getAccessToken();
            if (!token) {
                throw new Error("Token not found in local storage.");
            }
            const apiEndpoint = API_URL + "auth/verify";
            try {
                const response = await axios.post(apiEndpoint, { token });
                return response.status === 200;
            } catch (error) {
                console.error("Error verifying token:", error);
                return false;
            }
        },
};

export default AuthService;
