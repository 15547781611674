var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.getterTypeExercise.responsible.view)?_c('div',{staticClass:"gh-row-content",style:(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' ? 'margin-top: -10px;margin-bottom: 10px;' : '')},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'radio',
                    id: 'multi_open_responsible',
                    label: _vm.$t('responsible') + (_vm.getterTypeExercise.responsible.required ? '*' : ''),
                    title: _vm.$t('responsible'),
                    text: _vm.$t('select_responsible') + ':',
                    name: 'exercise[multi_open][responsible]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: _vm.getterTypeExercise.responsible.required,
                    checkparentdata: false,
                    style: '',
                    label_style: 'width: 149px',
                    popupStyle: 'min-width: 900px; width: 55%;'
                },"selected_input":_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.responsible ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.responsible : [],"items":_vm.getFeeds.feed_responsible}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('responsible') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.responsible && _vm.BaseCreationExerciseTable.exercise_data.multi_open.responsible[0] !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.responsible[0].text : ''))])]],2):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhInputTextField',{staticStyle:{"margin-top":"-5px"},attrs:{"datalabel":{
                        text: _vm.$t('name') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'multi_open_statement',
                        name: 'exercise[multi_open][statement]',
                        style: 'width: 600px',
                        value: _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.statement : '',
                    },"maxlength":"100"}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.statement : '')}})]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhTextarea',{staticStyle:{"width":"100%"},attrs:{"datalabel":{
                        text: _vm.$t('description'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'multi_open_description',
                        name: 'exercise[multi_open][description]',
                        value: _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.description : '',
                        required: false,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.description : '')}})]],2),_vm._l((_vm.getterTypeExercise.n_statements),function(item,index){return [_c('div',{key:'statement_' + index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhTextarea',{staticStyle:{"width":"100%"},attrs:{"datalabel":{
                            text: _vm.$t('statement') + ' ' + item,
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        },"datainput":{
                            id: 'exercise_multi_open_real_statement_' + index,
                            name: 'exercise[multi_open][real_statement][' + index + ']',
                            value: _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.real_statement[index] : '',
                            required: false,
                            edited: false
                        }}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('statement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.real_statement[index] : '')}})]],2),_c('div',{key:'substatement_' + index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('div',{staticStyle:{"width":"100%"}},[_c('GhTextarea',{staticStyle:{"width":"100%"},attrs:{"datalabel":{
                            text: _vm.$t('substatement') + ' ' + item,
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        },"datainput":{
                            id: 'exercise_multi_open_substatement_' + index,
                            name: 'exercise[multi_open][substatement][' + index + ']',
                            value: _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.substatement[index] : '',
                            required: false,
                            edited: true,
                            image: {
                                subscript: require('../../../../../../assets/img/text/subscript.svg'),
                                superscript: require('../../../../../../assets/img/text/superscript.svg'),
                                bold: require('../../../../../../assets/img/text/text-bold.svg'),
                                italic: require('../../../../../../assets/img/text/text-italic.svg'),
                                underline: require('../../../../../../assets/img/text/text-underline.svg'),
                            }
                        }}})],1)]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('substatement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.substatement[index] : '')}})]],2)]}),(_vm.getterTypeExercise.information.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('information') + (_vm.getterTypeExercise.information.required ? '*' : ''),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'multi_open_information',
                    name: 'exercise[multi_open][information]',
                    value: _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.information : '',
                    required: _vm.getterTypeExercise.information.required,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('information') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.information : '')}})]],2):_vm._e(),_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"5px","margin-bottom":"10px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(''))])]),(_vm.getterTypeExercise.evidence.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled')?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('text_after_download') + (_vm.getterTypeExercise.evidence.required ? '*' : ''),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'multi_open_text_evidence_after_download',
                    name: 'exercise[multi_open][text_evidence][after_download]',
                    value: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.text_evidence !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download : '',
                    required: _vm.getterTypeExercise.evidence.required,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                },"maxlength":"128"}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('text_after_download') + ''))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(_vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.text_evidence !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.text_evidence.after_download : '')}})]],2):_vm._e(),(_vm.getterTypeExercise.evidence.view)?_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('file'),
                style: '',
                class: '',
                id: ''
            },"datainput":{
                disabled: !_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' ? false : true,
                required: false,
                selected: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.type_file !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.type_file === 1 : '',
                type: 'radio',
                id: 'multi_open_text_evidence_after_download',
                value: 1,
                name: 'exercise[multi_open][text_evidence][type_file]'
            }}}),_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('graphic'),
                style: '',
                class: '',
                id: ''
            },"datainput":{
                disabled: !_vm.$root._route.params.view && _vm.$root._route.params.type !== 'enabled' ? false : true,
                required: false,
                selected: _vm.BaseCreationExerciseTable.exercise_data !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open !== undefined && _vm.BaseCreationExerciseTable.exercise_data.multi_open.type_file !== undefined ? _vm.BaseCreationExerciseTable.exercise_data.multi_open.type_file === 2 : '',
                type: 'radio',
                id: 'multi_open_text_evidence_after_download',
                value: 2,
                name: 'exercise[multi_open][text_evidence][type_file]'
            }}})],1):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }