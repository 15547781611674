<template>
    <div>
        <div class="gh-content-global" :style="$route.matched[0].props.default.questionnaireBackground !== undefined ? 'margin-left: 5px;' : 'padding-top: 0px;'">
            <template v-for="(itemEvaluation, indexEvaluation) in getterQuestionnaireEvaluation.evaluation[actualKey].childrens">
                <div v-if="itemEvaluation.current || itemEvaluation.finished" :key="'EVALUATED_ELEMENT' + indexEvaluation" :class="indexEvaluation === 0 ? 'gh_text_field-label T-subtitle-second' : 'T-text'" style="margin-right: unset">
                    <template v-if="indexEvaluation !== 0">
                        <div class="separator-line" style="margin-top: 10px; margin-bottom: 10px;"></div>
                    </template>
                    <div class="gh-row-content">
                        <div>
                            <div style="width: 100%;">
                                <label v-for="(element, keyElement) in itemEvaluation.name" :key="'EVALUATED' + keyElement" :class="keyElement === 0 ? 'gh_text_field-label T-subtitle-second' : 'gh_text_field-label T-text'">
                                    {{element + (keyElement === 0 ? '' : itemEvaluation.name.length === keyElement + 1 ? '.' : ':')}}
                                </label>
                            </div>
                        </div>
                        <template v-if="itemEvaluation.finished">
                            <label style="width: 75px; text-align: right;" class="gh_text_field-label T-subtitle-second">{{itemEvaluation.punctuation}}</label>
                        </template>
                    </div>
                    <template v-if="!itemEvaluation.finished">
                        <div class="gh-row-content" style="margin-top: -5px;">
                            <label class="T-text">
                                {{itemEvaluation.description}}
                            </label>
                        </div>
                    </template>
                    <template v-if="itemEvaluation.finished">
                        <div class="gh-row-content" style="margin-top: -5px;">
                            <label class="T-text">
                                {{itemEvaluation.result_description}}
                            </label>
                        </div>
                    </template>
                    <template v-if="!itemEvaluation.finished && ($root._route.params.complete === undefined || $root._route.params.complete === null)">
                        <div style="padding-top: 10px;">
                            <div class="gh-row-content radio-check-new-style" v-for="(itemAnswers, indexAnswers) in itemEvaluation.answers" :key="'ANSWERS' + indexAnswers + itemEvaluation.id">
                                <GhChecks
                                    :datalabel="{
                                        text: itemAnswers,
                                        style: '',
                                        class: 'T-text',
                                        id: ''
                                    }"
                                    :datainput="{
                                        disabled: $root._route.params.view,
                                        required: true,
                                        selected: itemEvaluation.selected === indexAnswers,
                                        type: 'radio',
                                        id: 'evaluate_answer_' + indexAnswers,
                                        value: indexAnswers,
                                        name: 'evaluate[' + parentId + '][' + itemEvaluation.id + '][answer]'
                                    }"
                                    :ref="'RefCheck' + indexAnswers"
                                    @click="setSelected(parentId, itemEvaluation.id, indexAnswers)"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import GhChecks from "fe-gh-checks-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'template_evaluate',
        props: ['actualKey', 'parentId'],
        components: {
            GhChecks
        },
        computed: {
            ...mapGetters(['getterQuestionnaireEvaluation'])
        },
        data(){
            return {
                refreshValorations: 0
            }
        },
        methods: {
            async setSelected(parent, element, item){
                await this.$store.dispatch('setElementSelected', {
                    actual_id: this.$root._route.params.actual_id,
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    parent: parent,
                    element: element,
                    item: item,
                    extra_id: this.$root._route.params.extra_id
                }, {root: true});
            }
        }
    }
</script>