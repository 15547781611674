<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'no_drop_selector_user',title: $t('user')}" v-if="haveData">
            <template v-slot:content>
                <form id="form_users">
                    <div class="gh-content-global">
                        <input type="hidden" name="user[from_company]" value="1"/>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style:'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'user_firstname',
                                        name: 'user[firstname]',
                                        style:'width: 10%',
                                        value: TemplateUser.user_data.firstname,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') +':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{TemplateUser.user_data.firstname}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('surnames') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'user_last_name',
                                        name: 'user[surname]',
                                        style: 'width: 20%',
                                        value: TemplateUser.user_data.surname,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surnames') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{TemplateUser.user_data.surname}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('email') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'user_email',
                                        name: 'user[email]',
                                        style: 'width: 20%',
                                        value: TemplateUser.user_data.email,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email')+':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{TemplateUser.user_data.email}}</label>
                            </template>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_user_save',
                    text: $t('save')
                }"
                type="submit"
                @click="submitUser"
            />
            <GhButton
                :datainput="{
                    id: 'btn_user_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';

    import {mapState} from "vuex";

    export default {
        name: "user_template",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhButton
        },
        computed: {
            ...mapState(['TemplateUser', 'getFeeds']),
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount() {
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getUser', {id: this.$root._route.params.id}, {root: true});
                this.haveData = true;
            }
        },
        methods: {
            async submitUser(){
                if(this.Global.checkRequired('form_users') === 'OK'){
                    const formData = new FormData(document.getElementById('form_users'));
                    this.$store.dispatch('setUser', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #user_group_selector_label {
        width: 150px !important;
        text-align: right;
    }
</style>
